import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

import type { AppSession } from '@/lib/auth/auth.types';
import { CONFIG } from '@/lib/config/config';

import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';

import { QUERY_KEYS_STR } from './query-keys.constants';
import type { User } from '@/types/user/user';

type Props = {
  session: AppSession;
};

export const fetchUser = async ({ session }: Props) => {
  if (session?.status !== 'authenticated') {
    throw new Error('Session must be provided and user should be authenticated');
  }

  const url = CONFIG.API.ENDPOINT + CONFIG.API.METHODS.USER;

  const response = await requestApi.get({ url, session });
  return await processApiResponseReactQuery<User>({ response });
};
interface ExtendedUser extends User {
  isPremiumUser: boolean;
  isBaseUser: boolean;
}
const transformUser = (data: User): ExtendedUser => {
  const isPremiumUser = data?.userModules.some((module) => module.key === 'premium');
  const isBaseUser = data?.userModules.some((module) => module.key === 'base');
  return { ...data, isPremiumUser, isBaseUser };
};

export const useUserQ = ({ refetchIntervalMs = 0 } = {}) => {
  const session = useSession();
  const refetchInterval = refetchIntervalMs ? { refetchInterval: refetchIntervalMs } : {};

  return useQuery({
    enabled: session.status === 'authenticated',
    queryKey: [QUERY_KEYS_STR.USER.GET_USER],
    queryFn: () => fetchUser({ session: session as AppSession }),
    select: transformUser,
    ...refetchInterval
  });
};
