export const getClickIds = (query, queryKeys) => {
  const clickIDs = {};
  queryKeys.forEach((key: string) => {
    const lowerCaseKey = key.toLowerCase();
    const clickIdMatch = lowerCaseKey.match(/^(int_)?(gclid|wbraid|gbraid|fbclid|msclkid|ttclid)$/);
    if (clickIdMatch && clickIdMatch[2]) {
      clickIDs[clickIdMatch[2]] = query?.[key];
    }
  });

  if (Object.keys(clickIDs).length) {
    return clickIDs;
  }

  return;
};
