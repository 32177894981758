import dayjs from 'dayjs';
import Dexie from 'dexie';
import urls from './urls';
import 'dayjs/locale/de';
import type { User } from '@/types/user/user';
// TODO CHECK IF IT WORKS WITH IMPORT INSTAD OF REQUIRE (ASK STEFFEN)
// require('dayjs/locale/de')

dayjs.locale('de');

const CONFIG = {
  API: {
    ENDPOINT: process.env.API_ENDPOINT || 'http://localhost:3000',
    OAUTH: {
      FB: {
        SIGN_UP: '/user/signup/facebook'
      },
      G: {
        SIGN_UP: '/user/signup/google'
      }
    },
    METHODS: {
      CATEGORY: {
        BY_ID: '/categories',
        BY_LEVEL: '/categories/by_level',
        BY_PARENT_CAT: '/categories/by_parent_category'
      },
      ASINS: '/asins',
      ASINS_DATE: '/asins/date',
      ASIN_METADATA: '/asin/meta',
      CHARTS: '/charts',
      KEYWORDS: '/keywords/',
      USER: '/user',
      USER_ADDRESS: '/user/address',
      USER_TRIAL: '/user/trial',
      USER_SESSION: '/user/sessions',
      USER_ACTIONS: '/user/actions',
      USER_ASINS: '/user/asins',
      USER_AMAZON_ACC: '/user/amazon-accounts',
      USER_AMAZON_ACC_BRANDS: '/user/amazon-accounts/brands',
      USER_SKU_DETAILS: '/user/sku-details',
      USER_SKU_DETAILS_CUSTOM_PRICES: '/user/sku-details-customprices',
      USER_SKU_DETAILS_DOWNLOAD_BY_AMAZON_ACCOUNT:
        '/user/sku-details/create-excel-list-by-amazon-account',
      USER_SKU_DETAILS_UPLOAD_BY_AMAZON_ACCOUNT:
        '/user/sku-details/upload-product-costs-by-amazon-account',
      USER_AMAZON_ACC_ACTIONS: '/user/amazon-accounts/actions',
      USER_REVIEW_ASINS: '/user/review-asins',
      USER_REVIEWS: '/user/reviews',
      USER_REVIEWS_ACTIONS: '/user/reviews/actions',
      USER_REVIEW_COMMENTS: '/user/reviews/comments',
      USER_REVIEW_HISTORY: '/user/reviews/history',
      USER_PAYMENTS: '/user/payments',
      USER_SUBSCRIPTIONS: '/user/subscriptions',
      USER_INVOICES: '/user/invoices/',
      USER_DASHBOARD: '/user/dashboard/',
      USER_DASHBOARD_WIDGET: '/user/dashboard/widget',
      USER_DASHBOARD_DATA: '/user/dashboard/data',
      USER_MAIN_DASHBOARD_DATA: '/user/dashboard/main-dashboard',
      USER_DASHBOARD_FEEDBACK: '/user/dashboard/handle-user-feedback',
      USER_FIXCOSTS: '/user/fixcosts/',
      USER_FIXCOSTS_ADD: '/user/fixcosts/fixcost',
      USER_FIXCOSTS_ADD_PRICE: '/user/fixcosts/fixcost-price',
      USER_SETTLEMENTS: '/user/settlements',
      USER_CHECK_PAID_STATUS: '/user/paid-status',
      PPC_CAMPAIGN_GROUP: '/ppc/campaign-group',
      PPC_CAMPAIGN_GROUP_KEYWORDS: '/ppc/campaign-group/keywords',
      USER_SHARING: '/user/sharing',
      USER_UPLOADS: '/user/uploads',
      USER_NOTIFICATIONS: '/user/notifications',
      USER_INTENTS: {
        PATH: '/user/intents',
        RECEIVING: '/user/intents/receiving',
        SENDING: '/user/intents/sending',
        SERVICE_PROVIDER: '/user/intents/service-provider',
        LOGISTICS_V2: '/user/intents/update-v2',
        CREATE_V2: '/user/intents/create-v2',
        ASSIGNMENTS_OPEN: '/user/intents/assignments-open',
        ASSIGNMENTS_COMPLETED: '/user/intents/assignments-completed',
        CREATE_QI: '/user/intents/create-qi'
      },
      USER_SHARING_SENDING: '/user/sharing/sending',
      USER_SHARING_RECEIVING: '/user/sharing/receiving',
      USER_DOCUMENTS: {
        GET_PACKINGLIST_CREDENTIALS: '/user/user-documents/get-packinglist-credentials',
        UPDATE_PACKINGLIST_CREDENTIALS: '/user/user-documents/update-packinglist-credentials'
      },
      LISTING_CHECKER: '/listing-checker',
      TRANSACTIONS: {
        PAYMENT_CHECKOUT_SESSION: '/payment/create-checkout-session',
        PAYMENT_CHECKOUT_SUBSCRIPTION_SESSION: '/payment/create-subscription-checkout-session',
        PAYMENT_CUSTOMER_PORTAL_SESSION: '/payment/create-portal-session',
        PAYMENT_CREATE_INTENT: '/payment/create-payment-intent',
        PAYMENT_USER_INTENTS: '/payment/intents',
        PAYMENT_SUCCESS: 'payment/success',
        PAYMENT_PAYMENT_PACKAGE: '/payment/payment-package',
        PAYMENT_PAYMENT_PACKAGE_PRICNG_TABLE: '/payment/payment-package/pricing-table'
      },
      ASSISTANT: {
        CTAS: '/assistant/cta',
        CTAS_DELAY_ALL: '/assistant/cta/delay-all',
        SIGNUP: '/user/signup/assistant',
        AUTOMATION_SUPPORT: '/assistant/cta/contact-automation-support',
        INVITE_AUTOMATION_EMAIL: '/assistant/cta/invite-automation-email',
        SETUP_AUTOMATION_FINISHED: '/assistant/cta/setup-automation-finished',
        CREATE_CTA: '/assistant/cta/create-onboarding-cta'
      },
      ASSIGNMENTS: '/assignments',
      RECOMMENDATIONS: {
        GET_RECOMMENDATIONS: '/recommendations/get-all',
        UPDATE_STATUS: '/recommendations/update-status',
        UPDATE_CASE_REIMBURSEMENT_ID: '/recommendations/update-case-reimbursement-id',
        UPDATE_MISSING_INBOUND_QUANTITY_SEND: '/recommendations/update-missing-inbound',
        GET_TOTAL_SAVINGS: '/recommendations/total-costsavings',
        UPDATE_BUNDLE_SKU: '/recommendations/update-bundle-sku',
        GET_TASK_COUNT: '/recommendations/tasks/count',
        UPDATE_SIZE_CHANGE_HIGHER_CONFIRM_WRONG:
          '/recommendations/size-change-higher-confirm-wrong-measurement',
        GET_TRANSLATED_COMMENTS: '/recommendations/product-feedbacks/translate-comments'
      },
      AUTOMATIONS: {
        GET_AUTOMATIONS: '/automations/get-all',
        UPDATE_AUTOMATIONS: '/automations/update',
        GET_ACTIVE_AUTOMATION_ACCOUNT: '/automations/get-active-automation-account',
        ADD_AUTOMATION_TO_CTA: '/automations/add-automation-to-cta'
      },
      USERFLOW: {
        GET_USERFLOW: '/userflow',
        UPDATE_USERLFOW: '/userflow/update',
        FINISH_USERFLOW: '/userflow/finish-userflow'
      },
      // KPI: '/admin/kpi',
      // USE_CASES: '/admin/usecases',
      // USER_DATA: '/admin/clientdata',
      ADMIN: {
        KPI: '/admin/kpi',
        USE_CASES: '/admin/usecases',
        USER_DATA: '/admin/clientdata',
        USER_DATA_ADD_PAID_STATUS: '/admin/clientdata/addpaidstatus',
        USER_DATA_UPDATE_CUSTOM_NAME: '/admin/clientdata/update-custom-name',
        USER_DATA_UPDATE_RIGHTS_CONFIRMED: '/admin/clientdata/update-rights-confirmed',
        UPDATE_ACTIVE_AUTOMATION_ACCOUNT: '/admin/update-active-automation-account',
        GET_ACTIVE_AUTOMATION_ACCOUNT: '/admin/get-active-automation-account',
        UPDATE_ACTIVE_PAYMENT_PACKAGE: '/admin/update-active-payment-package',
        GET_ACTIVE_PAYMENT_PACKAGE: '/admin/get-active-payment-package'
      },
      // USER_DATA_ADD_PAID_STATUS: '/admin/clientdata/addpaidstatus',
      // USER_DATA_UPDATE_CUSTOM_NAME: '/admin/clientdata/update-custom-name',
      // USER_DATA_UPDATE_RIGHTS_CONFIRMED: '/admin/clientdata/update-rights-confirmed',
      SUPPORT: '/support/contact-support',
      REPRICER: {
        GET_REPRICER: '/repricer/get-repricer',
        UPDATE_AUTOMATION_OR_MINIMAL_PRICE: '/repricer/update-automation-or-minimal-price'
      },
      CHAT: {
        GET_RANDOM_QUESTION: '/chat/random-question',
        POST_ASK_QUESTION: '/chat/ask-question',
        POST_ADD_REVIEW: '/chat/review',
        GET_CHAT_WIDGET_DATA: '/chat/get-chat-widget-data'
      }
    }
  },
  NEXT_API: {
    METHODS: {
      COOKIE: '/api/cookie'
    }
  },
  APP: {
    ROOT_URL: process.env.NEXT_PUBLIC_URL ? process.env.NEXT_PUBLIC_URL : 'http://localhost:4000'
  },
  URL: {
    ...urls
  },
  SETTINGS: {
    POST_LOGIN_URI: urls.POST_LOGIN.path,
    POST_LOGOUT_URI: urls.INDEX.path,
    LANGUAGES: ['de', 'en'] as const,
    LANGUAGE_FLAGS: {
      de: 'twemoji:flag-germany',
      en: 'twemoji:flag-united-kingdom',
      us: 'twemoji:flag-united-states'
    },
    NO_SKU_PREFIX: '__amz_tools_',
    USER_MODULES: {
      // SETTLEMENTS: 'settlements',
      SHARING: 'sharing',
      PPC: 'ppc',
      INTENTS: 'intents',
      LOGISTICS: 'logistics',
      PPC_REPORTING_DASHBOARD: 'ppc-reporting-dashboard',
      PAID_USER: 'paid',
      TRIAL_USER: 'trial'
    },
    DEFAULT_TOOLS: [
      {
        key: 'main-dashboard',
        url: urls.MAIN_DASHBOARD.path,
        isAllowed: () => true
      },
      {
        key: 'sales-dashboard',
        url: urls.DASHBOARD.path,
        isAllowed: (user: User) =>
          user.availablePlans && user.availablePlans.some((x) => x.category === 'dashboard')
      },
      {
        key: 'ppc-dashboard',
        url: urls.PPC_DASHBOARD.path,
        isAllowed: (user: User) =>
          user && user.userModules && Array.isArray(user.userModules)
            ? user.userModules.some((x) => x.key === 'ppc')
            : false
      },
      {
        key: 'ppc-reporting-dashboard',
        url: urls.PPC_DASHBOARD_REPORTING.path,
        isAllowed: () => true
      },
      {
        key: 'reviews',
        url: urls.REVIEWS_ASINS.path,
        isAllowed: () => true
      },
      {
        key: 'asins-explorer',
        url: urls.ASINS_EXPLORER.path,
        isAllowed: () => true
      },
      {
        key: 'listing-checker',
        url: urls.LISTING_CHECKER.path,
        isAllowed: () => true
      }
    ],
    COUNTRIES: [
      'at',
      'be',
      'bg',
      'cy',
      'cz',
      'de',
      'dk',
      'ee',
      'el',
      'es',
      'fi',
      'fr',
      'hr',
      'hu',
      'ie',
      'it',
      'lt',
      'lu',
      'lv',
      'mt',
      'nl',
      'pl',
      'pt',
      'ro',
      'se',
      'si',
      'sk',
      'uk'
    ],
    MARKETPLACES_REVIEWS: ['de', 'fr', 'uk', 'it', 'es', 'se', 'nl', 'pl'],
    MARKETPLACES_DASHBOARD: ['de', 'fr', 'uk', 'it', 'es', 'se', 'nl', 'pl'],

    REGIONS_DASHBOARD: ['eu', 'na'],
    REGIONS: {
      eu: ['de', 'fr', 'uk', 'it', 'es', 'se', 'nl', 'pl', 'ae', 'eg', 'be', 'in', 'sa', 'tr'],
      na: ['br', 'ca', 'mx', 'us']
    },

    PPC_CAMPAIGN_GROUP: {
      MAXBID_MINIMUM: 0.02,
      MAXBID_MAXIMUM: 9.99,
      DAILY_BUDGET_MINIMUM: 5.0,
      DAILY_BUDGET_MAXIMUM: 999
    }
  },
  INDEXED_DB: {
    NAME: 'amz.tools',
    USER_TABLE: 'user'
  },
  SENTRY: {
    DSN: 'https://b89eaae2354d41729aa87600b7cfc3e0@o4504254325719040.ingest.sentry.io/4504254330175488'
  },
  INTERCOM: {
    APP_ID: 'is3z7opi'
  },
  COOKIE_STORE: {
    CONSENT: 'amz_cookie_consent',
    LANGUAGE: 'NEXT_LOCALE', // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
    BEARER: 'amz_bearer'
  },
  GA: {
    ID: 'UA-171841068-1',
    TRACKING_ID: 'AW-613502992',
    EVENTS: {
      USER_ACTIVATED: 'AW-613502992/iaplCMn9w9cBEJCgxaQC',
      AGBS_CLICKED: 'AW-613502992/0FOQCIKwkPYCEJCgxaQC',
      TRIAL_STARTED: 'AW-613502992/0cAOCPah6PUCEJCgxaQC',
      PAYMENT_COMPLETED: 'AW-613502992/F6o2CJCnk_YCEJCgxaQC'
    }
  },
  GTAG: {
    ID: 'GAW-10988150747'
  },
  FB: {
    PIXEL_ID: '1127979550971500'
  },
  BUILD_HASH: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  DEPLOY_TARGET: process.env.NEXT_PUBLIC_VERCEL_URL,
  ENVIRONMENT: process.env.NEXT_PUBLIC_VERCEL_ENV || 'development', // production (live), preview (on preview domain) or development (local)
  BRANCH: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
  TABLE_MAX_ITEMS: 2000,
  TABLE_ITEMS_PER_CALL: 50,
  ROOT_CATEGORY_PSEUDOID: 'rootcat',
  PERMISSIONS: {
    MODULES: {
      DASHBOARD: 'dashboard',
      PRODUCT_COSTS: 'product_costs',
      RECOMMENDATIONS: 'recommendations'
    },
    OPERATIONS: {
      READ: 'read',
      WRITE: 'write'
    }
  },
  PAYMENT_PACKAGE: {
    BASE_STRIPE_PRODUCT_ID: 'prod_NhjKbEYqsM4Bi5'
  }
};

if (CONFIG.ENVIRONMENT === 'preview') {
  // CONFIG.API.ENDPOINT = 'https://stagingapi.amz.tools';
  CONFIG.API.ENDPOINT = process.env.API_ENDPOINT || 'https://stagingapi.getarthy.com';
}
if (CONFIG.ENVIRONMENT === 'production') {
  // CONFIG.API.ENDPOINT = 'https://api.amz.tools';
  CONFIG.API.ENDPOINT = 'https://api.getarthy.com';
  CONFIG.PAYMENT_PACKAGE.BASE_STRIPE_PRODUCT_ID = 'prod_Nk3NNX3Q0B2pwM';
}

// if (CONFIG.BRANCH === 'release/redesign-v2') {
//   CONFIG.API.ENDPOINT = 'https://api.amz.tools';
// }

// if (CONFIG.BRANCH === 'feature/redesign-pre-merge-v2') {
//   CONFIG.API.ENDPOINT = 'https://api.amz.tools';
// }
const db = new Dexie(CONFIG.INDEXED_DB.NAME);
db.version(1).stores({ [CONFIG.INDEXED_DB.USER_TABLE]: 'key' });

export { db, CONFIG };
