const ERRORS_TO_IGNORE = ['AUTOMATION_INACTIVE'];
import { logoutUser } from '@/lib/auth/auth.actions';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';

export const processApiResponseReactQuery = <T>({
  response,
  meta = false,
  schema = false,
  validation = false,
  returnAll = false // use it when you want to return all properties and not only .data in the response
}: {
  response: Response;
  meta?: boolean;
  validation?: boolean;
  schema?: boolean;
  returnAll?: boolean; // use it when you want to return all properties and not only .data in the response
}): Promise<T> => {
  const setNotificationMessage = useGlobalStore.getState().setNotificationMessage;
  if (!response) {
    setNotificationMessage([
      {
        msg: '404',
        type: 'error',
        namespace: 'api-errors'
      }
    ]);
  }

  if ('status' in response && response.status === 401) {
    if (process.env.NODE_ENV === 'development') {
      console.log('Unauthorized');
    } else {
      logoutUser();
    }

    setNotificationMessage([
      {
        msg: response.status.toString(),
        type: 'error',
        namespace: 'api-errors'
      }
    ]);
  }

  return response?.json().then((resultJson) => {
    if (!resultJson?.data && Array.isArray(resultJson?.errors) && resultJson.errors.length > 0) {
      // Map through all the errors to create an array of notifications.
      const errorNotifications = resultJson.errors
        // Filter out any errors that we want to ignore.
        .filter((error) => !ERRORS_TO_IGNORE.includes(error.code))
        .map((error) => ({
          msg: error.msg,
          type: 'error',
          namespace: 'api-errors'
        }));

      // Set a notificationMessages array of error messages to globalStore.
      setNotificationMessage(errorNotifications);
      throw new Error('API returned errors: ' + errorNotifications.map((e) => e.msg).join(', '));
    }
    if (resultJson.validation && validation) {
      return { ...resultJson.data, validation: resultJson.validation };
    }
    // not all endpoints have a meta object in the response
    if (resultJson.meta && meta) {
      // map them as child objects to the data object
      return { data: resultJson.data, meta: resultJson.meta };
    }
    if (resultJson.metadata && meta) {
      // map them as child objects to the data object
      return { data: resultJson.data, metadata: resultJson.metadata };
    }

    if (resultJson.schema && schema) {
      return { data: resultJson.data, schema: resultJson.schema };
    }
    return returnAll ? resultJson : resultJson.data;
  });
};
