export const QUERY_KEYS_STR = {
  ASSISTANT: {
    GET_CTAS: 'GET_CTAS',
    UPDATE_CTA_STATUS: 'UPDATE_CTA_STATUS'
  },
  USER: {
    GET_USER: 'GET_USER',
    PAID_STATUS: 'PAID_STATUS',
    GET_USER_NOTIFICATIONS: 'GET_USER_NOTIFICATIONS',
    GET_BRANDS: 'GET_BRANDS',
    GET_CLIENT_DATA: 'GET_CLIENT_DATA'
  },
  GET_TOTAL_COST_SAVINGS: 'GET_TOTAL_COST_SAVINGS',
  AMAZON_ACCOUNTS: {
    GET_AMAZON_ACCOUNTS: 'GET_AMAZON_ACCOUNTS',
    GET_AMAZON_ACCOUNTS_GET_ALL: 'GET_AMAZON_ACCOUNTS_GET_ALL',
    GET_AMAZON_ACCOUNTS_AND_SHARED: 'GET_AMAZON_ACCOUNTS_AND_SHARED'
  },
  RETURNS: {
    GET_RETURN_COMMENTS: 'GET_RETURN_COMMENTS'
  },
  TRANSLATION: {
    TRANSLATE: 'TRANSLATE'
  },
  REPRICER: {
    GET_REPRICERS: 'GET_REPRICERS'
  },
  PACKAGELIST_CREDENTIALS: {
    GET_PACKAGELIST_CREDENTIALS: 'GET_PACKAGELIST_CREDENTIALS'
  },
  ADMIN: {
    GET_KPIS: 'GET_KPIS',
    GET_USECASES: 'GET_USECASES'
  },
  BASE_DATA: {
    GET_FIXCOSTS: 'GET_FIXCOSTS',
    GET_SKU_DETAILS: 'GET_SKU_DETAILS',
    GET_CUSTOM_PRODUCT_COSTS: 'GET_CUSTOM_PRODUCT_COSTS'
  },
  USERASINS: {
    GET_USERASINS: 'GET_USERASINS'
  },
  PAYMENT: {
    GET_PRICNG_TABLE: 'GET_PRICNG_TABLE'
  }
};
