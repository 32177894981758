import { ReactElement, ReactNode, useEffect, useState } from 'react';

import '@/styles/app.scss';
import '@/styles/globals.css';
import '@/styles/toast.css';
import { LicenseInfo } from '@mui/x-license-pro';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import FlagProvider from '@unleash/proxy-client-react';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { CollapseDrawerProvider } from '@/lib/context/collapse-drawer.context';
import { unleashClient } from '@/lib/feature-flags/unleash-client';
import '@/lib/i18n/numeral';

import ErrorBoundaryWrapper from '@/components/error-boundary/error-boundary.component';
import ThemeProvider from '@/components/ui/theme-provider';
import dayjs from 'dayjs';
import { AmzScripts } from '@/components/amz-scripts/amz-scripts.component';
import { GlobalSideEffectsComponent } from '@/lib/global-side-effects/global-side-effects.component';
import { AbilityContext } from '@/lib/permissions/abilities/Can';
import { defineAbilityFor } from '@/lib/permissions/abilities/abilities';
import { AppPermissionsComponent } from '@/lib/permissions/app-permissons.component';
import { NextPage } from 'next';

declare global {
  interface Window {
    Intercom: typeof Intercom;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cookiebot: any;
  }
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const LICENCE_KEY =
  'a6f1f851ca6bb3f9d91aae1c64c1319dTz03OTk2NixFPTE3MzMzNDA5MjAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';
LicenseInfo.setLicenseKey(LICENCE_KEY);

const ability = defineAbilityFor();
// https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts#with-typescript
// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};
const MyApp = ({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) => {
  const [queryClient] = useState(
    () => new QueryClient({ defaultOptions: { queries: { staleTime: 1000 * 60 } } })
  );

  // Remove comments if you want to use web vitals
  // useReportWebVitals((metric) => {
  //   // console.log(metric);
  // });

  const getLayout = Component.getLayout ?? ((page) => page);
  const { locale } = useRouter();
  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  Router.events.on('routeChangeStart', () => {
    NProgress.configure({ showSpinner: false });
    NProgress.start();
  });

  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });

  return (
    <ErrorBoundaryWrapper>
      <FlagProvider unleashClient={unleashClient} startClient={true}>
        <SessionProvider session={session}>
          <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={pageProps.dehydratedState}>
              <AbilityContext.Provider value={ability}>
                <AppPermissionsComponent />
                <GlobalSideEffectsComponent />
                <CollapseDrawerProvider>
                  <ThemeProvider>{getLayout(<Component {...pageProps} />)}</ThemeProvider>
                  <AmzScripts />
                </CollapseDrawerProvider>
              </AbilityContext.Provider>
            </HydrationBoundary>
            <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
          </QueryClientProvider>
        </SessionProvider>
      </FlagProvider>
    </ErrorBoundaryWrapper>
  );
};

export default appWithTranslation(MyApp);
