var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"53Kc72x17GCGz-ZyqxzaH"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

import { CONFIG } from '@/lib/config/config';

Sentry.init({
  dsn: 'https://e2c6655f61a0436e8ea79490e5ce26f6@o4504254325719040.ingest.sentry.io/4505549809385472',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  // tracesSampler(samplingContext) {
  //   console.log('samplingContext:', samplingContext)
  //   return true;
  // },
  enabled: CONFIG.ENVIRONMENT !== 'development',

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: CONFIG.ENVIRONMENT === 'production' ? 0.1 : 1.0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      // maskAllText: true,
      blockAllMedia: true
    })
  ],
  tracePropagationTargets: [
    'localhost:3000',
    'localhost:4000/api/',
    /^https:\/\/app.getarthy.com\/api/,
    /^https:\/\/api.getarthy.com/,
    /^https:\/\/stagingapi.getarthy.com/
  ]
});
