import { UnleashClient } from 'unleash-proxy-client';

// Config from feature flags needs to be defined here, not in config.ts, because it can be used in urls.ts or in the config.ts file itself and it would cause a circular dependency
export const UNLEASH_CONFIG = {
  URL: process.env.NEXT_PUBLIC_UNLEASH_URL || 'https://unleash.getarthy.com/api/frontend',
  CLIENT_KEY: process.env.NEXT_PUBLIC_UNLEASH_CLIENT_KEY,
  ENVIRONMENT: process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'
};

// Unleash Feature Flags
const featureFlagsConfig = {
  environment: UNLEASH_CONFIG.ENVIRONMENT, // The environment your application is running in
  url: UNLEASH_CONFIG.URL || '', // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: UNLEASH_CONFIG.CLIENT_KEY || '', //|| 'default:development.a587cd824257f9192b7f7daa4daa42758e006b4a64ada562a8a41054X', // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 15, // How often (in seconds) the client should poll the proxy for updates
  appName: 'Arthy-Frontend' // The name of your application. It's only used for identifying your application
};

// This should be a singleton
export const unleashClient = new UnleashClient(featureFlagsConfig);
