export const MIXPANEL_EVENTS = {
  PAGE_VIEWED: 'Page Viewed',

  // User
  // USER_LOGIN: 'User Login',
  // USER_LOGOUT: 'User Logout',
  USER_SIGNUP: 'User Signup',
  USER_ACTIVATED: 'User Activated',
  // USER_FORGOT_PASSWORD: 'User Forgot Password',
  // USER_RESET_PASSWORD: 'User Reset Password',

  // Amazon Account
  AMAZON_ACCOUNT_CONNECTED: 'Amazon Account Connected',
  // AMAZON_ACCOUNT_DISCONNECTED: 'Amazon Account Disconnected',

  // User Subscription
  // USER_SUBSCRIBED: 'User Subscribed',
  // USER_CANCELLED_SUBSCRIPTION: 'User Cancelled Subscription',
  // USER_REACTIVATED_SUBSCRIPTION: 'User Reactivated Subscription',

  // Recommendations -> add source - onboarding or recommendation page or cta
  // RECOMMENDATION_RESOLVED: 'Recommendation Resolved', // add source - onboarding or recommendation page or cta
  // RECOMMENDATION_DELAYED: 'Recommendation Delayed', // add source - onboarding or recommendation page or cta
  // RECOMMENDATION_DISMISSED: 'Recommendation Dismissed' // add source - onboarding or recommendation page or cta
  RECOMMENDATIONS: {
    UPDATE_STARTED: 'Recommendation Update Started',
    FBA_MISPLACED_DAMAGED_INVENTORY: {
      UPDATED: 'FBA Misplaced Damaged Inventory Updated'
    }
  },
  TOGGLES: {
    UPDATE_STARTED: 'Toggle Update Started'
  },

  ONBOARDING: {
    FBA_MISPLACED_DAMAGED_INVENTORY_STARTED: 'Onboarding FBA Misplaced Damaged Inventory Started'
  }
};
