import { AmazonAccount } from '@/types/user/user-amazon-account.type';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const ZUSTAND_GLOBAL_STORE_KEY = 'globalStore';
type NotificationMessage = {
  msg: string | string[];
  namespace?: string;
  type: 'error' | 'success';
};

export type State = {
  selectedAmazonAccount?: AmazonAccount | null;
  notificationMessages: NotificationMessage[];
};
type Actions = {
  setAmazonAccount: (amazonAccount: AmazonAccount) => void;
  resetStore: () => void;
  setNotificationMessage: (newMessage: NotificationMessage[]) => void;
  clearNotificationMessages: () => void;
};

const initialState: State = {
  selectedAmazonAccount: null,
  notificationMessages: []
};

export const useGlobalStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      setAmazonAccount: (amazonAccount) => {
        set({ selectedAmazonAccount: amazonAccount });
      },
      resetStore: () => {
        set(initialState);
      },
      setNotificationMessage: (newMessage) => {
        const notificationMessagesState = get().notificationMessages;
        set({
          notificationMessages: [...notificationMessagesState, ...newMessage]
        });
      },
      clearNotificationMessages: () => {
        set({ notificationMessages: [] });
      }
    }),
    {
      name: ZUSTAND_GLOBAL_STORE_KEY,
      storage: createJSONStorage(() => localStorage)
    }
  )
);
