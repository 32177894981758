import { useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import breakpoints from '@/lib/constants/breakpoints.constant';
import { pxToRem } from '@/lib/helpers/utils/getFontValue';
import { palette } from '@/lib/styles/palette.style';
import shadows, { customShadows } from '@/lib/styles/shadows.style';
import typography from '@/lib/styles/typography.style';
interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const themeDirection = 'ltr';
  const isLight = 'light';

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 6 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      components: {
        MuiCssBaseline: {
          styleOverrides: () => ({
            body: {
              //   backgroundImage: 'url(/assets/logo-bg.svg)',
              //   backgroundRepeat: 'no-repeat',
              //   backgroundSize: 'contain',
            }
          })
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: pxToRem(14),
              fontWeight: 'normal'
            }
          }
        },
        MuiDialog: {
          defaultProps: {
            disableScrollLock: true
          }
        }
      }
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeProvider;
