import { useEffect, useRef } from 'react';
import { useUserQ } from '../hooks/queries/useUser.query';
import { AbilityContext } from './abilities/Can';
import { useAbility } from '@casl/react';
import { updateAbility } from './abilities/abilities';

import _ from 'lodash';
import { UserModule } from '@/types/user/user';
export const AppPermissionsComponent: React.FC = () => {
  const { data: user } = useUserQ();
  const ability = useAbility(AbilityContext);
  const userModules = user?.userModules;
  const roles = useRef<UserModule[] | null>(null);
  useEffect(() => {
    if (user && user?.userModules && !_.isEqual(user.userModules, roles.current)) {
      // Update user abilities based on userModules but only if userModules changed
      updateAbility({ ability, user });
      roles.current = user.userModules;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userModules, ability]);

  return null;
};
