import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import { useGlobalStore } from '../state-management/useGlobalStore';
import { ILinkObj } from './config.interface';

const defaultLinkHandling = (
  pathname: string,
  { exactMatch = true, isPublic = false, isAccountRoute = false } = {}
) => {
  const selectedAmazonAccountId = useGlobalStore.getState().selectedAmazonAccount?._id;

  if (!pathname) {
    console.error('no pathname passed to defaultLinkHandling()');
  }

  return {
    isPublic,
    path: isAccountRoute
      ? `/accounts/${selectedAmazonAccountId || 'main-account'}/${pathname}`
      : pathname,
    isActive: (router: NextRouter) => {
      if (!router) {
        console.error('no router passed to isActive()');
      }

      if (exactMatch === true) {
        return router.asPath === pathname;
      }
      return `${router.asPath}`.startsWith(pathname);
    },
    generateUrl: () => pathname,
    deserializeParams: () => ({})
  };
};

const links = {
  LP_MODULE: defaultLinkHandling('/module', {
    exactMatch: false,
    isPublic: true
  }),
  LP_MODULE_DASHBOARD: defaultLinkHandling('/module/dashboard', {
    isPublic: true
  }),
  LP_MODULE_CHROME_EXTENSION: defaultLinkHandling('/module/chrome-extension', {
    isPublic: true
  }),
  LP_MODULE_ASIN_EXPLORER: defaultLinkHandling('/module/asin-explorer', {
    isPublic: true
  }),
  LP_MODULE_REVIEWS: defaultLinkHandling('/module/reviews', {
    isPublic: true
  }),
  LP_MODULE_LISTING_CHECKER: defaultLinkHandling('/module/listing-checker', {
    isPublic: true
  }),
  LP_AMAZONSPAPI: defaultLinkHandling('/amazon-sp-api', { isPublic: true }),
  LP_CAREER: defaultLinkHandling('/career', { isPublic: true }),
  INDEX: defaultLinkHandling('/', { isPublic: true }),
  SETTLEMENTS: defaultLinkHandling('/settlements'),
  DASHBOARD: {
    ...defaultLinkHandling('dashboard/profit', {
      exactMatch: false,
      isAccountRoute: true
    }),
    generateUrl: ({ tab = '', path }) => {
      const pathSegments = path.split('/');
      if (pathSegments.length <= 5) {
        return `${path}${tab && tab.length > 0 ? `/${tab}` : ''}`;
      } else {
        pathSegments[5] = tab;
        const newPath = pathSegments.join('/');
        return newPath;
      }
    },
    deserializeParams: (query: ParsedUrlQuery) => {
      const { params } = query || {};
      return {
        tab: params && params[0] ? params[0] : null
      };
    }
  },
  PPC_DASHBOARD: {
    ...defaultLinkHandling('dashboard/ppc', {
      exactMatch: false,
      isAccountRoute: true
    }),
    generateUrl: ({ tab = '', path }) => {
      const pathSegments = path.split('/');
      if (pathSegments.length <= 5) {
        return `${path}${tab && tab.length > 0 ? `/${tab}` : ''}`;
      } else {
        pathSegments[5] = tab;
        const newPath = pathSegments.join('/');
        return newPath;
      }
    },
    deserializeParams: (query: ParsedUrlQuery) => {
      const { params } = query || {};
      return {
        tab: params && params[0] ? params[0] : null
      };
    }
  },
  PPC_DASHBOARD_REPORTING: {
    ...defaultLinkHandling('dashboard/ppc-reporting', {
      exactMatch: false,
      isAccountRoute: true
    }),
    generateUrl: ({ tab = '', path }) => {
      const pathSegments = path.split('/');
      if (pathSegments.length <= 5) {
        return `${path}${tab && tab.length > 0 ? `/${tab}` : ''}`;
      } else {
        pathSegments[5] = tab;
        const newPath = pathSegments.join('/');
        return newPath;
      }
    },
    deserializeParams: (query: ParsedUrlQuery) => {
      const { params } = query || {};
      return {
        tab: params && params[0] ? params[0] : null
      };
    }
  },
  CAMPAIGNGROUP_SETUP: defaultLinkHandling('/ppc/setup'),
  IMPRINT: {
    path: {
      en: 'https://www.getarthy.com/legal#imprint',
      de: 'https://www.getarthy.com/de/rechtliches#impressum'
    }
  },
  PRIVACY: {
    path: {
      en: 'https://www.getarthy.com/legal#privacy',
      de: 'https://www.getarthy.com/de/rechtliches#datenschutz'
    }
  },
  TERMS: {
    path: {
      en: 'https://www.getarthy.com/legal#terms',
      de: 'https://www.getarthy.com/de/rechtliches#agb_1'
    }
  },
  ASINS_EXPLORER: {
    ...defaultLinkHandling('/asin-explorer', { exactMatch: false }),
    generateUrl: (token = '') => `/asin-explorer${token && token.length > 0 ? `/${token}` : ''}`,
    deserializeParams: (query: ParsedUrlQuery) => {
      const { params } = query || {};
      return {
        token: params && params[0] ? params[0] : null
      };
    }
  },
  LISTING_CHECKER: defaultLinkHandling('/listing-checker'),
  LOGISTICS: defaultLinkHandling('/logistics'),
  QI: defaultLinkHandling('/quality-inspection'),
  SIGN_UP: defaultLinkHandling('/auth/sign-up/01-create-account'),
  SIGN_UP_CREATE_ACCOUNT: defaultLinkHandling('/auth/sign-up/01-create-account'),
  SIGN_UP_ACCOUNT_CONFIRMATION: defaultLinkHandling('/auth/sign-up/02-account-confirmation'),
  SIGN_UP_CONNECT_AMAZON_ACCOUNT: defaultLinkHandling('/auth/sign-up/03-connect-amazon-account'),
  SIGN_UP_CONNECT_ADVERTISING_ACCOUNT: defaultLinkHandling(
    '/auth/sign-up/04-connect-advertising-account'
  ),
  SIGN_UP_BOOK_PACKAGE: defaultLinkHandling('/auth/sign-up/04-book-package'),
  SIGN_UP_INVITE_SHARING_ACTIVATE: defaultLinkHandling('/auth/sign-up/sharing-invite-confirmation'),
  SIGNUP_ACTIVATE: {
    ...defaultLinkHandling('/auth/sign-up'),
    generateUrl: (token = '') => `/auth/sign-up${token && token.length > 0 ? `/${token}` : ''}`,
    deserializeParams: (query: ParsedUrlQuery) => {
      const { token } = query || {};
      return {
        token
      };
    }
  },
  USER_RESEND_ACTIVATION: defaultLinkHandling('/auth/sign-up/resend-activation'),
  USER_RESEND_PASSWORD: defaultLinkHandling('/auth/sign-up/resend-password'),
  LOGIN: defaultLinkHandling('/auth/login', { isPublic: true }),
  LOGIN_SSO: {
    ...defaultLinkHandling('/auth/login', { isPublic: true }),
    generateUrl: (token = '', redirect: string | null = null) => {
      let pathSegment = '';
      if (token.length > 0) {
        pathSegment = `/${token}`;
        if (redirect && redirect.length > 0) {
          pathSegment = `${pathSegment}/${redirect}`;
        }
      }
      return `/auth/login${pathSegment}`;
    },
    deserializeParams: (query: ParsedUrlQuery, linkObj: ILinkObj) => {
      const { params } = query || {};
      const redirectKey = params && params[1] ? params[1] : null;
      let redirect = null;
      if (redirectKey && linkObj[redirectKey] && linkObj[redirectKey].path) {
        // @ts-ignore
        redirect = linkObj[redirectKey].path;
      }
      return {
        token: params && params[0] ? params[0] : null,
        redirect
      };
    }
  },
  POST_LOGIN: defaultLinkHandling('/', { isPublic: false }),
  LOGOUT: defaultLinkHandling('/auth/logout'),
  RELOGIN: defaultLinkHandling('/auth/login'),
  REVIEWS_ASINS: {
    ...defaultLinkHandling('dashboard/reviews', {
      isAccountRoute: true
    }),
    generateUrl: ({ tab = '', path }) => {
      const pathSegments = path.split('/');
      if (pathSegments.length <= 5) {
        return `${path}${tab && tab.length > 0 ? `/${tab}` : ''}`;
      } else {
        pathSegments[5] = tab;
        const newPath = pathSegments.join('/');
        return newPath;
      }
    },
    deserializeParams: (query: ParsedUrlQuery) => {
      const { params } = query || {};
      return {
        tab: params && params[0] ? params[0] : null
      };
    }
  },
  REVIEWS_LIST: {
    ...defaultLinkHandling('reviews/read', {
      isAccountRoute: true
    }),
    generateUrl: (asins = []) =>
      `/reviews/read${Array.isArray(asins) && asins.length > 0 ? `/${asins.join('::')}` : ''}`,
    deserializeParams: (query: ParsedUrlQuery) => {
      const { params } = query || {};
      return { asins: params && params[0] ? `${params[0]}`.split('::') : null };
    }
  },
  SETTINGS: defaultLinkHandling('/settings'),
  SETTINGS_GENERAL: defaultLinkHandling('/settings/general'),
  SETTINGS_PLAN_AND_BILLING: defaultLinkHandling('/settings/plan-and-billing'),
  SETTINGS_DANGER_ZONE: defaultLinkHandling('/settings/danger-zone'),
  AMAZON_ACCOUNTS: defaultLinkHandling('/settings/amazon-accounts'),
  SETTINGS_SHARING: defaultLinkHandling('/settings/sharing'),
  ADD_AMAZON_ACC: defaultLinkHandling('/settings/amazon-accounts-new'),
  ADD_ADVERT_ACC: defaultLinkHandling('/settings/advertising-accounts-new'),
  // MODULES: defaultLinkHandling('/settings#modules'),
  // MODULES_REVIEWS: defaultLinkHandling('/settings#module-reviews'),
  // MODULES_ASINS_EXPLORER: defaultLinkHandling(
  //   '/settings#module-asins-explorer'
  // ),
  // MODULES_DASHBOARD: defaultLinkHandling('/settings#module-dashboard'),
  // ADDRESS: defaultLinkHandling('/settings#address'),
  // INVOICES: defaultLinkHandling('/settings#invoices'),
  SHARING: defaultLinkHandling('/sharing'),
  SUBSCRIPTION: defaultLinkHandling('/subscription'),
  CONFIRMATIONS: defaultLinkHandling('/confirmations'),
  ASSIGNMENTS: defaultLinkHandling('/assignments'),
  QUALITY_INSPECTION: defaultLinkHandling('/quality-inspection/book-qi'),
  QUALITY_INSPECTION_GET_QI: defaultLinkHandling('/quality-inspection/get-qi'),
  TRANSACTIONS: defaultLinkHandling('/transactions'),
  TRANSACTIONS_SUCCESS: defaultLinkHandling('/transactions/success'),
  BASE_DATA: defaultLinkHandling('base-data', {
    isAccountRoute: true
  }),
  RECOMMENDATIONS: defaultLinkHandling('recommendations', {
    isAccountRoute: true
  }),
  AUTOMATIONS: defaultLinkHandling('automations', {
    isAccountRoute: true
  }),
  FIXCOSTS: defaultLinkHandling('base-data/fixcosts', {
    isAccountRoute: true
  }),
  // FIXCOSTS: {
  //   ...defaultLinkHandling('/base-data/fixcosts'),
  //   generateUrl: (account = '', search: string | null = null) => {
  //     let pathSegment = '';
  //     if (account.length > 0) {
  //       pathSegment = `/${account}`;
  //       if (search && search.length > 0) {
  //         pathSegment = `${pathSegment}/${search}`;
  //       }
  //     }
  //     return `/reviews/read${pathSegment}`;
  //   },
  //   deserializeParams: (query: ParsedUrlQuery) => {
  //     const { params } = query || {};
  //     return {
  //       account: params && params[0] ? params[0] : null,
  //       search: params && params[1] ? params[1] : null
  //     };
  //   }
  // },
  PRODUCTS: defaultLinkHandling('base-data/products', {
    isAccountRoute: true
  }),
  // PRODUCTS: {
  // ...defaultLinkHandling('/base-data/products'),
  // generateUrl: (account = '', search: string | null = null) => {
  //   let pathSegment = '';
  //   if (account.length > 0) {
  //     pathSegment = `/${account}`;
  //     if (search && search.length > 0) {
  //       pathSegment = `${pathSegment}/${search}`;
  //     }
  //   }
  //   return `/basedata/products${pathSegment}`;
  // },
  // deserializeParams: (query: ParsedUrlQuery) => {
  //   const { params } = query || {};
  //   return {
  //     account: params && params[0] ? params[0] : null,
  //     search: params && params[1] ? params[1] : null
  //   };
  // },
  ADMIN_USER_DATA: defaultLinkHandling('/admin/client-data'),
  ADMIN_SETTINGS: defaultLinkHandling('/admin/settings'),
  ADMIN_KPI: defaultLinkHandling('/admin/kpi'),
  ADMIN_RECOMMENDATIONS: defaultLinkHandling('/admin/use-cases'),
  MAIN_DASHBOARD: {
    ...defaultLinkHandling('dashboard', {
      isAccountRoute: true
    }),
    generateUrl: ({ tab = '', path }) => {
      const pathSegments = path.split('/');
      if (pathSegments.length <= 5) {
        return `${path}${tab && tab.length > 0 ? `/${tab}` : ''}`;
      } else {
        pathSegments[5] = tab;
        const newPath = pathSegments.join('/');
        return newPath;
      }
    },
    deserializeParams: (query: ParsedUrlQuery) => {
      const { params } = query || {};
      return {
        tab: params && params[0] ? params[0] : null
      };
    }
  },
  ACCOUNTS_ACCOUNT: {
    ...defaultLinkHandling('/accounts/5c7e72ebd8b543383f095d57'),
    generateAccountId: (accountId = '') => `/accounts/${accountId}`
  },
  ONBOARDING: {
    ...defaultLinkHandling('onboarding', {
      isAccountRoute: true
    })
  },
  CHAT: { ...defaultLinkHandling('/chat'), isAccountRoute: true }
};

export default links;
