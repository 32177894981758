import { signOut } from 'next-auth/react';

import { CONFIG, db } from '@/lib/config/config';

import { MixpanelWrapper } from '@/lib/mixpanel/useMixpanel';

export const logoutUser = async () => {
  await fetch(CONFIG.NEXT_API.METHODS.COOKIE, {
    method: 'DELETE'
  });
  await db.table(CONFIG.INDEXED_DB.USER_TABLE).clear();
  MixpanelWrapper.reset();
  signOut({ callbackUrl: CONFIG.URL.LOGIN.path });
};
