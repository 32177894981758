import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CONFIG } from '@/lib/config/config';
import { requestApi } from '@/lib/api/request-api.helper';
import { useSession } from 'next-auth/react';
import { AppSession } from '@/lib/auth/auth.types';
import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { QUERY_KEYS_STR } from '@/lib/hooks/queries/query-keys.constants';

interface UpdateUserProps {
  session: AppSession;
  last_seen?: number;
  language?: string;
  selected_amazon_account?: string;
}
const updateUserRequest = async ({
  session,
  last_seen,
  language,
  selected_amazon_account
}: UpdateUserProps) => {
  const url = CONFIG.API.ENDPOINT + CONFIG.API.METHODS.USER;
  if (session.status !== 'authenticated') {
    throw new Error('Session must be provided and user should be authenticated');
  }

  const response = await requestApi.patch({
    url,
    session,
    body: { last_seen, language, selected_amazon_account }
  });
  return await processApiResponseReactQuery({ response });
};

export const useUpdateLastSeenStatusMutation = () => {
  const session = useSession();
  const last_seen = new Date().getTime();
  return useMutation({
    mutationFn: () => updateUserRequest({ session: session as AppSession, last_seen })
  });
};
export const useUpdateUserSelectedAmazonAccountMutation = () => {
  const session = useSession();
  return useMutation({
    mutationKey: ['updateSelectedAmazonAccount'],
    mutationFn: ({ selected_amazon_account }: { selected_amazon_account: string }) =>
      updateUserRequest({ session: session as AppSession, selected_amazon_account })
  });
};
export const useUpdateUserLanguageMutation = () => {
  const session = useSession();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ language }: { language: string }) =>
      updateUserRequest({ session: session as AppSession, language }),
    onMutate: async (newLanguage) => {
      // invalidate user query
      const previousUser = queryClient.getQueryData([QUERY_KEYS_STR.USER.GET_USER]);
      if (previousUser) {
        await queryClient.cancelQueries([QUERY_KEYS_STR.USER.GET_USER]);
        queryClient.setQueryData([QUERY_KEYS_STR.USER.GET_USER], {
          ...previousUser,
          language: newLanguage
        });
      }
      return { previousUser };
    },
    onError: (err, _newLanguage, context) => {
      console.log('err:', err);
      queryClient.setQueryData([QUERY_KEYS_STR.USER.GET_USER], context?.previousUser);
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEYS_STR.USER.GET_USER]);
    }
  });
};
