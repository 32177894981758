import { createContext, useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const initialState = {
  isCollapse: false,
  collapseClick: false,
  collapseHover: false,
  onToggleCollapse: () => null,
  onHoverEnter: () => null,
  onHoverLeave: () => null
};

const CollapseDrawerContext = createContext(initialState);

const CollapseDrawerProvider = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [collapse, setCollapse] = useState({
    click: false,
    hover: false
  });

  useEffect(() => {
    if (isMobile) {
      setCollapse({
        click: false,
        hover: false
      });
    }
  }, [isMobile]);

  const handleToggleCollapse = () => {
    setCollapse({ ...collapse, click: !collapse.click });
  };

  const handleHoverEnter = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: true });
    }
  };

  const handleHoverLeave = () => {
    if (collapse.click) {
      setCollapse({ ...collapse, hover: false });
    }
  };

  return (
    <CollapseDrawerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isCollapse: collapse.click && !collapse.hover,
        // @ts-ignore
        isCollapsed: collapse.click,
        collapseClick: collapse.click,
        collapseHover: collapse.hover,
        onToggleCollapse: handleToggleCollapse,
        onHoverEnter: handleHoverEnter,
        onHoverLeave: handleHoverLeave
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
};

CollapseDrawerProvider.propTypes = {
  children: PropTypes.node
};

export { CollapseDrawerProvider, CollapseDrawerContext };
