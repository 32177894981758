import { useEffect } from 'react';
import { CONFIG } from '../config/config';

import mixpanel, { Dict, Query } from 'mixpanel-browser';

const isProd = process.env.NODE_ENV === 'production';

const checkAllowed = () => {
  if (typeof window === 'undefined') return false;
  return window?.Cookiebot?.consent?.marketing && isProd;
};

const isAllowed = checkAllowed();

mixpanel.init('8518f904416559617e50eebf522b86b3', {
  api_host: `${CONFIG.APP.ROOT_URL}/mp`,
  opt_out_tracking_by_default: true
});

export const MixpanelWrapper = {
  identify: (id: string) => {
    if (isAllowed) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (isAllowed) mixpanel.alias(id);
  },
  track: (name: string, props?: Dict) => {
    if (isAllowed) mixpanel.track(name, props);
  },
  track_links: (query: Query, name: string) => {
    if (isAllowed)
      mixpanel.track_links(query, name, {
        referrer: document.referrer
      });
  },
  people: {
    set: (props: Dict) => {
      if (isAllowed) mixpanel.people.set(props);
    }
  },
  reset: () => {
    if (isAllowed) mixpanel.reset();
  }
};

export const useMixpanelOptIn = () => {
  useEffect(() => {
    const checkOptInMixpanel = async () => {
      if (window?.Cookiebot?.consent.marketing) {
        mixpanel.opt_in_tracking();
      }
    };
    window.addEventListener('CookiebotOnAccept', checkOptInMixpanel);

    checkOptInMixpanel();

    return () => {
      window.removeEventListener('CookiebotOnAccept', checkOptInMixpanel);
    };
  }, []);

  return MixpanelWrapper;
};
