import numeral from 'numeral';

if (numeral.locales.de === undefined) {
  numeral.register('locale', 'de', {
    delimiters: {
      thousands: '.',
      decimal: ','
    },
    abbreviations: {
      thousand: 'K.',
      million: 'Mio.',
      billion: 'B',
      trillion: 'T'
    },
    ordinal() {
      return '.';
    },
    currency: {
      symbol: '€'
    }
  });
}

if (numeral.formats['numeral-without-extension'] === undefined) {
  numeral.register('format', 'numeral-without-extension', {
    regexps: {
      format: /(q)/,
      unformat: /(q)/
    },
    format(value, _, roundingFunction) {
      const output = numeral._.numberToFormat(value, '0.0 a', roundingFunction).split(' ');

      return output.length > 1 ? output.slice(0, -1) : output[0];
    },
    unformat(string) {
      return numeral._.stringToNumber(string) * 0.01;
    }
  });
}
