import { useEffect } from 'react';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/nextjs';
import { setCookie } from 'cookies-next';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import { CONFIG } from '../config/config';
import useLocalStorage from '../hooks/useLocalStorage';
import { getClickIds } from './global-side-effects-helpers';
import { MIXPANEL_EVENTS } from '../mixpanel/mixpanel-events.constants';
import { MixpanelWrapper, useMixpanelOptIn } from '../mixpanel/useMixpanel';
import { useUpdateLastSeenStatusMutation } from '../queries/user/mutation/use-update-user.mutation';
import { useUserQ } from '../hooks/queries/useUser.query';
import { useUnleashContext } from '@unleash/proxy-client-react';

export const GlobalSideEffectsComponent: React.FC = () => {
  const { isLoading, data: userState } = useUserQ();
  const { query, pathname } = useRouter();

  const queryKeys = Object.keys(query);
  const utmKey: string = queryKeys.find((key) => /utm_source/.test(key.toLowerCase())) || '';
  const updateLastSeenStatusMutation = useUpdateLastSeenStatusMutation();
  const clickIDs = getClickIds(query, queryKeys);
  const [, setEmail] = useLocalStorage('mail', false);
  const session = useSession();
  useMixpanelOptIn();

  const updateContext = useUnleashContext();
  useEffect(() => {
    MixpanelWrapper.track(MIXPANEL_EVENTS.PAGE_VIEWED, {
      page: window.location.pathname,
      utm_source: query?.[utmKey],
      click_ids: clickIDs
    });
  }, [clickIDs, query, utmKey]);
  useEffect(() => {
    if (!userState?.username) return;
    updateContext({
      username: userState.username
    });
  }, [userState?.username, updateContext]);

  useEffect(() => {
    // We need to set the email in the localStorage, so we can use it in the whatfix script
    if (session?.data?.user?.email) {
      // Sentry
      Sentry.setUser({ email: session.data.user.email });
      if (typeof window !== undefined) {
        setEmail(session.data.user.email);
      }

      // Mixpanel:
      MixpanelWrapper.identify(session?.data?.user?.email);
    }
  }, [session?.data?.user?.email, setEmail]);

  // Last_Seen:
  useEffect(() => {
    if (isLoading) {
      return;
    }
    // dot not update last_seen if user is in the sign up invite sharing activate page
    // it was causing a race condition with the update sharing receiver
    if (pathname === CONFIG.URL.SIGN_UP_INVITE_SHARING_ACTIVATE.path) {
      return;
    }

    const last_seen = userState && userState.last_seen ? userState.last_seen : null;
    if (!last_seen || dayjs(last_seen).format('DDMMYYYY') !== dayjs().format('DDMMYYYY')) {
      // removing update from last_seen, because it is maybe causing update errors on backend
      // updateLastSeenStatusMutation.mutate();
      // dispatch(updateUserLastSeen());
    }
  }, [userState?.last_seen, pathname, isLoading, userState, updateLastSeenStatusMutation]);

  // Sets UTM_Source
  if (query?.[utmKey]) {
    setCookie('utm_source', query?.[utmKey], {
      expires: dayjs().add(30, 'days').toDate()
    });
  }

  if (clickIDs) {
    setCookie('click_ids', clickIDs, {
      expires: dayjs().add(30, 'days').toDate()
    });
  }

  return null;
};
