import { alpha } from '@mui/material/styles';

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export const PRIMARY = {
  lighter: '#8bd6ff',
  light: '#54bdff',
  main: '#2185fb',
  dark: '#0e67e7',
  darker: '#1253bb'
};

export const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A'
};

export const INFO = {
  lighter: '#bae6fd',
  light: '#7dd3fc',
  main: '#38bdf8',
  dark: '#0ea5e9',
  darker: '#0284c7'
};

export const SUCCESS = {
  lighter: '#bbf7d0',
  light: '#86efac',
  main: '#4ade80',
  dark: '#16a34a',
  darker: '#15803d'
};

export const WARNING = {
  lighter: '#fef08a',
  light: '#fde047',
  main: '#facc15',
  dark: '#eab308',
  darker: '#ca8a04'
};

export const ERROR = {
  lighter: '#fecaca',
  light: '#fca5a5',
  main: '#f87171',
  dark: '#ef4444',
  darker: '#dc2626'
};

export const GREY = {
  0: '#FFFFFF',
  100: '#f9fafb',
  200: '#f3f4f6',
  300: '#e5e7eb',
  400: '#d1d5db',
  500: '#9ca3af',
  600: '#6b7280',
  700: '#52525b',
  800: '#374151',
  900: '#1f2937',
  500_8: alpha('#d1d5db', 0.08),
  500_12: alpha('#d1d5db', 0.12),
  500_16: alpha('#d1d5db', 0.16),
  500_24: alpha('#d1d5db', 0.24),
  500_32: alpha('#d1d5db', 0.32),
  500_48: alpha('#d1d5db', 0.48),
  500_56: alpha('#d1d5db', 0.56),
  500_80: alpha('#d1d5db', 0.8)
};

export const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

export const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

export const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action }
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action }
  }
};

export const colors = {
  colorTableOdd: '#f7f7f7',
  colorTextLight: '#999',
  colorGrey: '#e6e6e6',
  colorGrey2: '#d6d6d6',
  colorText: '#404040',
  colorHighlightOrange: '#ff7700',
  colorHighlightOrangeContrast: '#fff'
};

export const SAVINGS_COLOR = '#10b981';

export const REVENUE_COLOR = 'rgb(4 120 87)';
export const ORGANIC_REVENUE_COLOR = 'rgb(16 185 129)';
export const PPC_REVENUE_COLOR = 'rgb(110 231 183)';
export const UNITS_COLOR = 'rgb(3 105 161)';
export const ORGANIC_UNITS_COLOR = 'rgb(14 165 233)';
export const PPC_UNITS_COLOR = ' rgb(125 211 252)';
export const ORDERS_COLOR = 'rgb(12 74 110)';
export const AMAZON_FEE_COLOR = 'rgb(249 115 22)';
export const COSTS_PPC_COLOR = 'rgb(120 53 15)';
export const SPONSORED_PRODUCTS_COLOR = ' rgb(41 37 36)';
export const SPONSORED_DISPLAY_COLOR = ' rgb(87 83 78)';
export const SPONSORED_BRANDS_COLOR = 'rgb(168 162 158)';
export const CREDITS_COLOR = 'rgb(163 230 53)';
export const PROFITS_COLOR = '#4ade80';
export const MARGE_COLOR = '#16a34a';
export const ROI_COLOR = 'rgb(148 163 184)';
export const REFUND_COSTS_COLOR = 'rgb(185 28 28)';
export const REFUNDED_ITEMS_COLOR = 'rgb(239 68 68)';
export const REFUNDED_ITEMS_PERCENTAGE_COLOR = 'rgb(248 113 113)';
export const RETURN_ITEMS_COLOR = 'rgb(67 56 202)';
export const RETURN_ITEMS_RESELLABLE_COLOR = 'rgb(99 102 241)';
export const RETURN_ITEMS_RESELLABLE_PERCENTAGE_COLOR = 'rgb(165 180 252)';

// PPC
export const BUDGET_COLOR = 'rgb(217 70 239)';
export const CAMPAIGN_COLOR = 'rgb(34 197 94)';

export default palette;
