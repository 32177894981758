import { useState } from 'react';

import dynamic from 'next/dynamic';
import Script from 'next/script';
import { CONFIG } from '@/lib/config/config';
const ChatbotComponent = dynamic(() => import('../chatbot/chatbot.component'), {
  ssr: false
});

export const AmzScripts: React.FC = () => {
  const [intercomLoaded, setIntercomLoaded] = useState(false);
  return (
    <>
      {intercomLoaded && <ChatbotComponent />}
      <Script
        id='google-tag-manager'
        async
        defer
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-KF8P562');`
        }}
      />
      <Script
        id='whatfix-loader'
        src='https://eucdn.whatfix.com/prod/a255e7de-4803-4579-87e8-ea5071b7d822/initiator/initiator.nocache.js'
        async
        defer
      />
      <Script
        async
        defer
        type='text/plain'
        data-cookieconsent='marketing'
        id='intercom'
        onLoad={() => {
          console.log('intercom loaded');
          setIntercomLoaded(true);
        }}
        dangerouslySetInnerHTML={{
          __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${CONFIG.INTERCOM.APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
        }}
      />
      <Script defer async src='https://js.stripe.com/v3/pricing-table.js' />
    </>
  );
};
