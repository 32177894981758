import { ReceivingUser, UserSharingResponse } from '@/types/user/sharing/user-sharing.type';
import { User } from '@/types/user/user';
import { AbilityBuilder, InferSubjects, MongoAbility, createMongoAbility } from '@casl/ability';

const USER_ROLES = {
  AMZ_SCALE_MANAGED: 'amzscale_managed',
  LOGISTICS: 'logistics',
  PAID_USER: 'paid', // role to flag users that payed for recommendation features
  // REQUESTED_PAID_USER: 'requested_paid_user', // role to flag users that are interested in paying for recommendation features
  ADMIN_KPIS: 'admin_kpis', // admin kpis access
  ADMIN_ALL: 'admin_all', // Acces to all admin (kpis, recomendations and user data)
  TRIAL_USER: 'trial', // role to flag users that are in trial period
  BASE_USER: 'base', // base user with only access to base data and dashboards
  PREMIUM_USER: 'premium', // premium user with full access but restrictions for sharing & automations
  UNLIMITED_USER: 'unlimited', // role to flag users that payed for all features
  ENTERPRISE_USER: 'enterprise', // enterprise user with full access and user defined pricing
  EDIT_AUTOMATION_FOR_OTHER_USERS: 'edit_automation' // role to edit some data values regarding automation (set custom_account_name or all rights state in admin dashboard)
} as const;

type Actions = 'see' | 'read' | 'activate' | 'share-with' | 'click' | 'edit';
type Subjects =
  | 'main_dashboard '
  | 'profit_dashboard'
  | 'ppc_dashboard'
  | 'base_data'
  | 'automation_page'
  | 'automation'
  | 'toggle'
  | 'recommendations'
  | 'reviews'
  | 'amazon_account'
  | 'sharing_add_button'
  | 'edit_automation'
  | 'chat'
  | Partial<ReceivingUser>;

export type AppAbility = MongoAbility<[Actions, InferSubjects<Subjects>]>;
export const defineAbilityFor = () => {
  const { build } = new AbilityBuilder<AppAbility>(createMongoAbility);

  return build();
};

// sharing_key is used to maintain compatibillity with
// urlToPermissionMapping
export const SUBJECTS = {
  AMAZON_ACCOUNT: {
    name: 'amazon_account' as const
  },
  MAIN_DASHBOARD: {
    name: 'main_dashboard' as const,
    url: '/accounts/[account]/dashboard/[[...params]]'
  },
  PROFIT_DASHBOARD: {
    name: 'profit_dashboard' as const,
    // sharing_key: ['dashboard'],
    url: '/accounts/[account]/dashboard/profit/[[...params]]'
  },
  PPC_DASHBOARD: {
    name: 'ppc_dashboard' as const,
    url: '/accounts/[account]/dashboard/ppc-reporting/[[...params]]'
  },
  BASE_DATA: {
    name: 'base_data' as const,
    url: '/accounts/[account]/base-data/[[...params]]'
  },
  AUTOMATIONS_PAGE: {
    name: 'automation_page' as const,
    url: '/accounts/[account]/automations'
  },
  AUTOMATIONS: {
    name: 'automation' as const
  },
  TOGGLES: {
    name: 'toggle' as const
  },
  RECOMMENDATIONS: {
    name: 'recommendations' as const,
    url: '/accounts/[account]/recommendations'
  },
  REVIEWS: {
    name: 'reviews' as const,
    url: '/accounts/[account]/dashboard/reviews/[[...params]]'
  },
  SHARING_ADD_BUTTON: {
    name: 'sharing_add_button' as const
  },
  EDIT_AUTOMATION_FOR_OTHER_USERS: {
    name: 'edit_automation' as const
  },
  CHAT: {
    name: 'chat' as const,
    url: '/accounts/[account]/chat'
  }
};

export const urlToSubject = (url) => {
  const subjectFound = Object.keys(SUBJECTS).find((key) => {
    return SUBJECTS[key].url === url;
  });

  if (subjectFound) {
    return SUBJECTS[subjectFound].name;
  }

  return null;
};

export const MAX_SHARING_SENDING_QUANTITY = {
  [USER_ROLES.BASE_USER]: 0,
  [USER_ROLES.PREMIUM_USER]: 3
};

type EditAutomationPermissionsProps = {
  can: typeof AbilityBuilder.prototype.can;
};

const editAutomationPermissions = ({ can }: EditAutomationPermissionsProps) => {
  const {
    EDIT_AUTOMATION_FOR_OTHER_USERS: { name: EditAutomation }
  } = SUBJECTS;

  can('activate', EditAutomation);
};

const baseUserPermissions = ({ can }) => {
  const {
    MAIN_DASHBOARD: { name: MainDashboard, url: MainDashboardUrl },
    PROFIT_DASHBOARD: { name: ProfitDashboard, url: ProfitDashboardUrl },
    RECOMMENDATIONS: { name: RecommendationsPage, url: RecommendationsPageUrl },
    AUTOMATIONS_PAGE: { name: AutomationsPage, url: AutomationsPageUrl },
    PPC_DASHBOARD: { name: PPCDashboard, url: PPCDashboardUrl },
    BASE_DATA: { name: BaseData, url: BaseDataUrl },
    REVIEWS: { name: ReviewsDashboard, url: ReviewsDashboardUrl }
  } = SUBJECTS;

  can('see', RecommendationsPage);
  can('see', AutomationsPage);
  can('see', ReviewsDashboard);
  can('see', MainDashboard);
  can('see', ProfitDashboard);
  can('see', PPCDashboard);
  can('see', BaseData);

  can('see', MainDashboardUrl);
  can('see', ProfitDashboardUrl);
  can('see', PPCDashboardUrl);
  can('see', BaseDataUrl);
  can('see', RecommendationsPageUrl);
  can('see', AutomationsPageUrl);
  can('see', ReviewsDashboardUrl);

  can('edit', MainDashboard);
  can('edit', ProfitDashboard);
  can('edit', PPCDashboard);
  can('edit', BaseData);
};

type PremiumUserPermissionsProps = {
  can: typeof AbilityBuilder.prototype.can;
  sharing?: UserSharingResponse | null;
};

const premiumUserPermissions = ({ can, sharing }: PremiumUserPermissionsProps) => {
  const {
    REVIEWS: { name: ReviewsDashboard },
    RECOMMENDATIONS: { name: RecommendationsPage },
    AUTOMATIONS_PAGE: { name: AutomationsPage },
    SHARING_ADD_BUTTON: { name: SharingAddButton },
    TOGGLES: { name: Togggles },
    CHAT: { name: Chat }
  } = SUBJECTS;

  can('see', ReviewsDashboard);
  can('see', RecommendationsPage);
  can('see', AutomationsPage);
  can('click', SharingAddButton);
  can('activate', Togggles);

  can('edit', RecommendationsPage);
  can('edit', ReviewsDashboard);
  can('edit', AutomationsPage);

  can('see', Chat);
  if (sharing && sharing.data.sending) {
    const unique_receivers_map = new Map();

    for (const sending of sharing.data.sending) {
      const sharingStatus = sending.users[0].status;
      if (sharingStatus === 'pending' || sharingStatus === 'accepted') {
        unique_receivers_map.set(sending.users[0]._id.toString(), sending.users[0]);
      }
    }

    // If the user has not shared with the maximum number of users, he can share with anyone
    if (unique_receivers_map.size < MAX_SHARING_SENDING_QUANTITY[USER_ROLES.PREMIUM_USER]) {
      can('share-with', 'ReceivingUser');
    }

    // If the user has already shared with the maximum number of users, he can only keep sharing with those same users
    if (unique_receivers_map.size === MAX_SHARING_SENDING_QUANTITY[USER_ROLES.PREMIUM_USER]) {
      unique_receivers_map.forEach((unique_receiver) => {
        can('share-with', 'ReceivingUser', { username: unique_receiver.username });
        can('share-with', 'ReceivingUser', { email: unique_receiver.email });
      });
    }
  }
};

const unlimitedEnterpriseAndTrialUserPermissions = ({ can }) => {
  const {
    AUTOMATIONS: { name: Automations }
  } = SUBJECTS;

  can('activate', Automations);
  can('share-with', 'ReceivingUser');
};

type UpdateAbilityProps = {
  ability: AppAbility;
  user: User;
  sharing?: UserSharingResponse | null;
};

export const updateAbility = ({ ability, user, sharing = null }: UpdateAbilityProps) => {
  const { can, rules } = new AbilityBuilder<AppAbility>(createMongoAbility);

  const {
    BASE_USER,
    PREMIUM_USER,
    UNLIMITED_USER,
    ENTERPRISE_USER,
    TRIAL_USER,
    EDIT_AUTOMATION_FOR_OTHER_USERS
  } = USER_ROLES;

  const userModulesKeysArr = user?.userModules.map((userModule) => userModule.key);
  if (userModulesKeysArr.includes(EDIT_AUTOMATION_FOR_OTHER_USERS)) {
    editAutomationPermissions({ can });
  }

  if (userModulesKeysArr.includes(BASE_USER)) {
    baseUserPermissions({ can });
  }

  if (userModulesKeysArr.includes(PREMIUM_USER)) {
    baseUserPermissions({ can });
    premiumUserPermissions({ can, sharing });
  }

  if (
    userModulesKeysArr.includes(UNLIMITED_USER) ||
    userModulesKeysArr.includes(ENTERPRISE_USER) ||
    userModulesKeysArr.includes(TRIAL_USER)
  ) {
    baseUserPermissions({ can });
    premiumUserPermissions({ can, sharing });
    unlimitedEnterpriseAndTrialUserPermissions({ can });
  }

  ability.update(rules);
};
