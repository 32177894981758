import type { AppSession } from '@/lib/auth/auth.types';
import { getSession } from 'next-auth/react';

type TRequestMethods = 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';

interface State {
  user: {
    bearer_token: string;
  };
}
interface RequestHeaders {
  [key: string]: string;
}
interface FetchRequestProps<T> {
  method?: TRequestMethods;
  url: string;
  state?: State;
  session?: AppSession;
  headers?: RequestHeaders;
  body?: T;
  signal?: AbortSignal;
  withoutSession?: boolean;
}

// TODO: refactor this method to use session JWT token instead of state for Bearer Token

export const fetchRequest = async <T>({
  method,
  url,
  session,
  state,
  headers: headersArg,
  body,
  signal,
  withoutSession = false
}: FetchRequestProps<T>): Promise<Response> => {
  let headers = headersArg;

  // Sentry.addBreadcrumb({ // TODO
  //   category: 'fetch',
  //   message: `${method} ${url}`,
  //   level: Sentry.Severity.Info
  // });
  // Use session from next-auth (new mode) or state (legacy mode)

  if (session?.data?.user?.bearer_token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${session.data.user.bearer_token}`
    };
  } else if (state && state.user && state.user.bearer_token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${state.user.bearer_token}`
    };
    // fallback if there is no session or state
  } else if (withoutSession) {
    // console.log('without session');
  } else if (!session && !state) {
    console.warn('no session or state, using redux session');
    const reduxSession = await getSession();
    headers = {
      ...headers,
      Authorization: `Bearer ${reduxSession?.user?.bearer_token}`
    };
  }
  let options: RequestInit = { headers };
  if (body) {
    options = {
      ...options,
      body: JSON.stringify(body),
      headers: { ...headers, 'Content-Type': 'application/json' }
    };
  }

  return fetch(url, { ...options, method, signal }).catch((error) => {
    console.error(`could not fetch ${url}`, error);
    return Promise.reject(error);
  });
};

export const requestApi = {
  get: <T>(props: FetchRequestProps<T>) => fetchRequest<T>({ ...props, method: 'GET' }),
  post: <T>(props: FetchRequestProps<T>) => fetchRequest<T>({ ...props, method: 'POST' }),
  patch: <T>(props: FetchRequestProps<T>) => fetchRequest<T>({ ...props, method: 'PATCH' }),
  put: <T>(props: FetchRequestProps<T>) => fetchRequest<T>({ ...props, method: 'PUT' }),
  delete: <T>(props: FetchRequestProps<T>) => fetchRequest<T>({ ...props, method: 'DELETE' })
};
