import { Component, type ReactNode } from 'react';

import { type TFunction, withTranslation } from 'next-i18next';

interface IProps {
  children: ReactNode;
  t: TFunction;
}

interface IState {
  hasError: boolean;
  eventId: null | number;
  modalOpen: boolean;
}
// TODO: Implement Sentry error handling and reporting
class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false, eventId: null, modalOpen: true };
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: unknown, errorInfo: unknown) {
    // Sentry.withScope(scope => { // TODO
    //   scope.setExtras(errorInfo);
    //   const eventId = Sentry.captureException(error);
    //   this.setState({ eventId });
    // });
    console.log('ComponentDidCatch');
  }

  render() {
    const { children } = this.props;
    // const { children } = this.props;
    // const { hasError, modalOpen, eventId } = this.state;

    // when there's not an error, render children untouched
    return <>{children}</>;
  }
}

const ErrorBoundaryWrapper = withTranslation()(ErrorBoundary);

export default ErrorBoundaryWrapper;
